import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  CustomInput,
  Label,
  FormFeedback
} from 'reactstrap';
import axios from 'axios';
import "../../assets/css/mrb.css";

const CustomerInquiry = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    inquiryType: '',
    inquiryContent: '',
    file: null,
  });

  const [errors, setErrors] = useState({});

  // 입력 값 검증
  const validate = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "이름을 입력해 주세요.";
    if (!formData.contact) newErrors.contact = "연락처를 입력해 주세요.";
    if (!formData.inquiryContent) newErrors.inquiryContent = "문의 내용을 입력해 주세요.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // 입력 값 변경 처리
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 파일 변경 처리
  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  // 폼 제출 처리
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('contact', formData.contact);
    formDataToSend.append('inquiryType', formData.inquiryType);
    formDataToSend.append('inquiryContent', formData.inquiryContent);
    if (formData.file) {
      formDataToSend.append('file', formData.file);
    }

    try {
      await axios.post('http://tb.mrbcare.com:5000/api/inquiry', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          withCredentials: true
        },
        timeout: 10000
      });
      alert('문의가 성공적으로 제출되었습니다.');
    } catch (err) {
      /*
      console.error(err);
      alert('문의 제출에 실패했습니다.');
      */
      if (axios.isCancel(err)) {
        console.log('Request canceled');
      } else if (err.code === 'ECONNABORTED') {
        console.log('A timeout occurred while waiting for data.');
      } else if (err.code === 'ECONNRESET') {
        console.log('Connection reset by peer');
      } else {
        console.error(err.message);
        alert('문의 제출에 실패했습니다.');
      }
    }
  };

  return (
    <Container className="mt-5">
      <span className="mrb-title">상담을 원하시는 분은 문의하실 내용을 알려주세요.</span>
      <FormGroup check className="mb-3">
        <Label check>
          <Input type="checkbox" /> {/* 체크박스가 제대로 보이도록 수정 */}
          {' '}
          개인정보처리방침에 동의합니다.
        </Label>
      </FormGroup>
      <Row className="mb-3">
        <Col sm="6">
          <FormGroup check>
            <Label check>
              <Input type="radio" name="inquiryType" value="제품문의" onChange={handleInputChange} />{' '}
              제품문의
            </Label>
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup check>
            <Label check>
              <Input type="radio" name="inquiryType" value="A/S문의" onChange={handleInputChange} />{' '}
              A/S문의
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <FormGroup>
            <Label for="name" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>이름</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="이름을 입력해 주세요."
              onChange={handleInputChange}
              invalid={!!errors.name}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup>
            <Label for="contact" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>회신가능한 연락처 (전화번호, 이메일 등)</Label>
            <Input
              type="text"
              name="contact"
              id="contact"
              placeholder="전화번호 또는 이메일을 입력해 주세요."
              onChange={handleInputChange}
              invalid={!!errors.contact}
            />
            <FormFeedback>{errors.contact}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-3">
        <Label for="inquiryContent" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>문의내용</Label>
        <Input
          type="textarea"
          name="inquiryContent"
          id="inquiryContent"
          placeholder="문의하고자 하는 내용을 입력해 주세요."
          onChange={handleInputChange}
          invalid={!!errors.inquiryContent}
        />
        <FormFeedback>{errors.inquiryContent}</FormFeedback>
      </FormGroup>
      <FormGroup className="mb-4">
        <Label for="file" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>파일 선택</Label>
        <CustomInput
          type="file"
          id="file"
          name="file"
          label="선택한 파일 없음"
          onChange={handleFileChange}
        />
      </FormGroup>
      <Button color="primary" onClick={handleSubmit}>상담하기</Button>
    </Container>
  );
};

export default CustomerInquiry;
